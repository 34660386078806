@media screen and (max-width: 601px) {
  .header {
    margin-top: -10vmin;
  }

  .leadText {
    margin: -5vmin 80px 50px 10px;
  }

  .whatWeDoPanel h1 {
    font-size: 6vmin;
  }

  .getStartedContainer {
    flex-direction: column;
  }

  .getStartedContainer h2 {
    font-size: 5vmin;
  }

  .getStartedContainer p {
    font-size: 4.5vmin;
  }

  .whatWeDoArrowIcon {
    transform: rotate(90deg);
    margin-top: 3vmin;
    margin-left: 15vmin;
  }

  .getStartedSteps {
    padding-bottom: 0px;
    width: 100%;
  }

  .testimonialsCardDeck {
    flex-direction: column;
    align-items: center;
  }

  .testimonialCard {
    margin-bottom: 20px;
    width: 250px;
  }

  .publicGalleryPage h2 {
    font-size: 6vmin;
  }

  .videoCardTitle {
    font-size: 5vmin;
  }

  .videoCardFooter {
    font-size: 4vmin;
  }

  .videoCardImage{
    width: 33vmax;
    height: 23vmax;
  }

  .whatWeDoCenteredContainer h3 {
    font-size: 5.5vmin;
  }

  .messageBoxContainer {
    margin-left: 5vmin;
    margin-right: 5vmin;
  }

  .providerProfileSection {
    display: flex;
    flex-direction: column;
  }

  .providerProfileBodyContainer {
    margin-left: 20px;
    margin-right: 20px;
  }

  .providerProfileName {
    text-align: center;
  }

  .providerProfileUsername {
    text-align: center;
  }

  .providerPublicVideosButton {
    max-width: 100%;
  }

  .videoTableCellContainer {
    flex-direction: column;
  }

  .listGroupContainerRow {
    flex-direction: column;
  }

  .listGroupRow {
    flex-direction: column;
  }

  .providerProfileAndLikeContainer {
    display: flex;
    flex-direction: column;
  }

  .nextStepsContainer {
    flex-direction: column;
  }

  .nextStep1Container {
    width: 100%;
  }

  .spacer {
    display: flex;
    flex-direction: column;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .navbar-collapse {
    background: white;
  }

  .multipleButtonsContainer {
    flex-direction: column;
  }
}
