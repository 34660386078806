@media screen and (min-width: 602px) {
  .testimonialsCardDeck {
    flex-direction: row;
  }

  .whatWeDoPanel h1 {
    font-size: 5vmin;
  }

  .getStartedContainer h2 {
    margin-bottom: 30px;
    font-size: 4vmin;
  }

  .getStartedContainer p {
    margin-left: 25px;
    font-size: 3vmin;
  }

  .getStartedSteps {
    margin-bottom: 15vmin;
  }

  .whatWeDoArrowIcon {
    margin-left: 3vmin;
  }

  .testimonialsPanel h1 {
    font-size: 5vmin;
  }

  .testimonialCardTitle {
    font-size: 2.5vmin;
  }

  .testimonialCardFooter {
    font-size: 2vmin;
  }

  .testimonialCard {
    width: 400px;
  }

  .goToPublicVideosPanel h1 {
    font-size: 5vmin;
  }

  .goToPublicVideosPanel h3 {
    font-size: 3vmin;
  }

  .videoPanel h1 {
    font-size: 3.5vmin;
  }

  .videoCard {
    width: 30vmax;
    height: 30vmax;
  }

  .videoCardTitle {
    font-size: 2vmin;
  }

  .videoCardFooter {
    font-size: 1.5vmin;
  }

  .videoCardImage{
    width: 25vmax;
    height: 15vmax;
  }

  .whatWeDoCenteredContainer h3 {
    font-size: 2.5vmin;
  }

  .messageBoxContainer {
    margin-left: 40vmin;
    margin-right: 40vmin;
  }
}
