.page {
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
}

.pageWithFooter {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: whitesmoke;
}

.pageHeader {
  margin-bottom: 40px;
}

.legalTextContainer {
  margin-bottom: 20px;
}

.legalTextContainer li {
  margin: 20px;
}

.legalTextContainer h4 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.homePageContainer {
  margin: 0;
  display: flex;
  flex-direction: column;
  background-color: white;
}

.leadContainer {
  position: relative;

  /* The image used */
  background-image: url("./assets/home_background.jpg");

  /* Center and scale the image */
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;

  /* Full height */
  height: 100vh;
}

.snapchatLeadContainer {
  position: relative;

  /* The image used */
  background-image: url("./assets/home_background.jpg");

  /* Center and scale the image */
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;

  /* Full height */
  height: 125vh;
}

.socialLeadContainer {
  position: relative;

  /* The image used */
  background-image: url("./assets/home_background.jpg");

  /* Center and scale the image */
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

.App-logo {
  height: 120px;
  width: 120px;
}

.navContainer {
  flex: 1;
  justify-content: flex-end;
  align-items: flex-end;
  margin-right: 20px;
}

.navLink {
  margin: 20px;
}

.leadText {
  margin: 50px;
}

.leadText h1 {
  font-size: 6vmin;
  margin-bottom: 3vmin;
}

.leadText h3 {
  font-size: 4vmin;
}

.leadButton {
  margin-top: 40px;
}

.leadBottom {
  background-color: rgba(0,0,0,0.5);
  padding: 40px;
  position: absolute;
  bottom: 0px;
  width: 100%;
  text-align: center;
}

.leadBottom h1 {
  color: white;
  padding-bottom: 20px;
  font-size: 4.5vmin;
}

.leadBottom h3 {
  color: lightgray;
  font-size: 4vmin;
}

.whatWeDoPanel {
  width: 100%;
  height: 100%;
  color: black;
  padding-top: 40px;
  padding-left: 40px;
  padding-right: 40px;
}

.whatWeDoPanel h1 {
  text-align: center;
  margin-bottom: 50px;
}

.getStartedContainer {
  display: flex;
  margin-bottom: 30px;
}

.getStartedContainer p {
  margin-left: 25px;
}

.getStartedSteps {
  display: flex;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 50px;
}

.getStartedStepsText {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding-left: 25px;
  padding-right: 25px;
}

.getStartedStepsGraphic {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 300px;
}

.getStartedCTAContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  border: 1px solid black;
  margin: 20px;
  padding: 20px;
}

.getStartedCTAText {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
}

.getStartedCTAButtonContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.whatWeDoCarousel {
  width: 300px;
  height: 300px;
}

#startingAtContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#startingAt5Dollars {
  font-size: 6em;
  line-height: 1.0;
  margin-bottom: 10px;
}

.whatWeDoCenteredContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 40px;
  margin-top: 40px;
}

.whatWeDoCenteredContainer h3 {
  font-size: x-large;
  text-align: center;
}

.whatWeDoGuaranteeIcon {
  height: 24vmin;
  width: 24vmin;
}

.providerProfilePage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: whitesmoke;
}

.providerProfilePage h2 {
  text-align: center;
  text-decoration: underline;
}

.providerProfileSection {
  display: flex;
  flex-direction: row;
}

.providerProfileAvatarContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 50px;
}

.providerProfileAvatar {
  border-radius: 50%;
  height: 15vmax;
  width: 15vmax;
  padding: 1vmin;
}

.providerProfileBodyContainer {
  display: flex;
  flex-direction: column;
  margin-right: 120px;
}

.providerProfileName {
  font-size: 2.5em;
  line-height: 1;
}

.providerProfileUsername {
  font-size: 1em;
  color: rgb(80, 80, 80);
  line-height: 0.5;
  margin-bottom: 50px;
}

.providerProfileBioContainer {

}

.providerProfileTextHeader {
  font-size: 1.5em;
}

.providerProfileBioText {
  font-size: 1em;
  margin-bottom: 40px;
}

.providerProfileMessageButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 50px;
}

.providerProfileLink {
  text-decoration: none;
  color: black;
}

.providerProfileVideos {
  flex: 1;
}

.providerProfileVideos h2{
  padding-top: 5vmin;
}

.providerUploadButton {
  margin-bottom: 40px;
}

.videoTableCellContainer {
  display: flex;
  flex-direction: row;
}

.videoTableVideoContainer {
  display: flex;
  flex-direction: row;
}

.videoTableThumbnailImage {
  background-color: black;
  width: 150px;
  height: 150px;
  display: inline-block;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.videoTableTextContainer {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  width: 300px;
}

.videoTableTitle {
  font-size: 10px;
  font-weight: bold;
  margin-bottom: 10px;
}

.videoTableDescription {
  font-size: 10px;
}

.videoTableDateContainer {
  display: flex;
  flex-direction:column;
  justify-content: flex-end;
  flex: 1;
}

.videoTableDate {
  font-size: 10px;
}

.videoTableStatusContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
  align-items: flex-end;
}

.videoTableStatusTextContainer {
  font-size: 10px;
  font-weight: bold;
  margin-bottom: 10px;
}

.videoTableStatusSubtextContainer {
  font-size: 10px;
}

.whatWeDoArrowIcon {
  height: 4.5vmin;
  width: 4.5vmin;
}

.testimonialsPanel {
  height: 100%;
  width: 100%;
  padding: 40px;
  background-color: wheat;
}

.testimonialsPanel h1 {
  text-align: center;
  padding-bottom: 40px;
}

.testimonialsCardDeck {
  display: flex;
  justify-content: space-evenly;
  padding-left: 50px;
  padding-right: 50px;
}

.testimonialCard {
  text-align: center;
}

.testimonialCardBody {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.testimonialCardFooter {
  background-color: white;
  border: none;
}

.goToPublicVideosPanel {
  height: 100%;
  width: 100%;
  padding: 40px;
  background-color: lightcyan;
}

.goToPublicVideosPanel h1 {
  text-align: center;
  padding-bottom: 20px;
}

.goToPublicVideosPanel h3 {
  text-align: center;
  padding-bottom: 40px;
}

.goToPublicVideosButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.footer {
  height: 100%;
  width: 100%;
  padding: 40px;
  background-color: rgb(50, 50, 50);
}

.footerLink {
  color: gainsboro;
  font-size: small;
  text-decoration: none;
}

.footerCopyright {
  color: gainsboro;
  font-size: small;
  margin-top: 20px;
}

.outreachFooter {
  height: 100%;
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
  background-color: rgb(50, 50, 50);
}

.outreachFooterLogoContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.outreachFooterBrand {
  justify-content: center;
  color: white;
}

.button-link {
  background: none!important;
  border: none;
  padding: 0!important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}

.button-link:hover {
  color: blueviolet;
}

.videoPanel {
  height: 100%;
  width: 100%;
  padding: 40px;
  background-color: transparent;
}

.videoPanel h1 {
  padding-bottom: 40px;
  text-decoration: underline;
}

.videoPanelNoVideos {
  font-size: 1em;
  color: darkgray;
}

.videoDeck {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-left: -1vmin;
}

.videoCard {
  background-color: transparent;
  text-align: center;
  align-items: center;
  border: none;
  margin-bottom: 4vmin;
}

.videoCardBody {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.videoCardFooter {
  background-color: transparent;
  border: none;
}

.videoCardImage {
  object-fit: scale-down;
  padding-bottom: 4vmin;
}

.videoPanel {
  padding-top: 2vmin;
}

.publicGalleryPage {
  background-color: wheat;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.publicGalleryPage h1 {
  text-align: center;
  padding-bottom: 2.5vmin;
}

.publicGalleryPage h2 {
  text-align: center;
  text-decoration: underline;
  padding-top: 5vmin;
}

.modalVideoCard {
  margin: auto;
  border: none;
}

.modalVideo {
  max-height: 43vmax;
  max-width: 40vmax;
  margin: 0 auto;
}

.modalTitle {
  width: 100%;
  text-align: center;
  padding-left: 38px; /* padding is equal to the size of modalCloseButton to properly center the modalTitles*/
}

.modalCloseButton {
  font-weight: bold;
}

.modalFooter {
  display: block;
  text-align: center;
}

.publicGalleryVideos {
  background-color: transparent;
  flex: 1;
}

.messageButton {
  margin: 25px;
}

.messageBox {
  height: 300px;
  display: flex;
  flex-direction: column-reverse;
  border: 0.5px solid black;
  overflow: auto;
  margin-bottom: 10px;
}

.messageBoxScroll {
  display: flex;
  flex-direction: column;
}

.sentMessage {
  align-self: flex-end;
  display: flex;
  flex-direction: column;
}

.receivedMessage {
  align-self: flex-start;
}

.messageBubble {
  border-radius: 15px;
  margin: 2.5px;
  padding: 10px;
  max-width: 40vmin;
  display: inline-block;
}

.message {
  overflow-wrap: break-word;
}

.alignRight {
  align-self: flex-end;
}

.blueBubble {
  background-color: rgba(100, 148, 237, 0.527);
}

.grayBubble {
  background-color: rgba(128, 128, 128, 0.466);
}

.messageThreadItem{
  padding-bottom: 2vmin;
  padding-top: 2vmin;
  margin-bottom: 10px;
  border-radius: 5px;
}

.messageListContainer{
  display: flex;
  justify-content: center;
}

/*
 * Containers
 */

.column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.centeredColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.columnCenteredVertically {
  display: flex;
  flex-direction: column;
  flex: 1;
  color: white;
}

.nextStepsContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  margin: 25px;
}

.nextStep1Container {
  display: flex;
  flex-direction: column;
  width: 60%;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid black;
  align-items: space-between;
}

.nextStep2Container {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 20px;
  justify-content: center;
}

.socialVideoContainer {
  width: 60%;
}

.callToActionContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 5px;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 5px;
}

.multipleButtonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.imageButton {
  background-color: transparent;
  border: none;
}

/*
 * Forms
 */

.formContainer {
  margin-left: 40px;
  margin-right: 40px;
}

.form {
  margin-bottom: 20px;
}

.formAlert {
  padding: 10px;
  width: 50vw;
}

.formAlertContainer {
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: space-between;
}

.formAlertCloseButton {
  font-weight: bold;
}

.formCheckbox {
  margin-bottom: 40px;
}

.formGroup {
  margin-bottom: 20px;
}

.formTextarea {
  width: 100%;
}

.formTitle {
  margin-bottom: 20px;
  text-align: center;
}

.formDescription {
  font-weight: bold;
}

.formInputSmall {
  width: 150px;
}

.signUpFormContainer {
  width: 50%;
}

.signInContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
}

.signInButton {
  margin-bottom: 30px;
}

.snapchatFormContainer {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
}

.snapchatFormButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 40px;
}

.containerMarginBottom {
  margin-bottom: 30px;
}

/*
 * Text
 */

 .centered {
   text-align: center;
 }

.subheader {
  margin-bottom: 40px;
}

.titleMarginBottom {
  margin-bottom: 20px;
}

.subheaderHint {
  color: darkgray;
  padding-left: 40px;
}

.pageTextLarge {
  font-size: 2.5vmin;
}

blockquote {
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  quotes: "\201C""\201D""\2018""\2019";
}

blockquote:before {
  color: #ccc;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}

blockquote p {
  display: inline;
}

.pageTextEmphasis {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 2.5vmin;
  margin-top: 40px;
  margin-bottom: 40px;
}

.nextStepText {
  display: flex;
  flex-direction: column;
  padding-left: 25px;
  padding-right: 25px;
}

/*
 * Table
 */

.ReactTable .rt-tr .action {
  transition: all .2s ease;
  text-align: center;
  color: red;
  transform: scale(0);
}

.ReactTable .rt-tr:hover .action {
  transform: scale(1.3);
}

.ReactTable .rt-tr:hover .rt-td {
  background: yellow;
}

/*
 * Alerts
 */

.alertContainer {
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: space-between;
}

.alert {
  padding: 10px;
  width: 50vw;
}

.alertCloseButton {
  font-weight: bold;
}

/*
 * ListGroups
 */

/* Switches to column in mobile */
.listGroupContainerRow {
  display: flex;
  flex-direction: row;
}

.listGroupColumn {
  display: flex;
  flex-direction: column;
}

/* Switches to column in mobile */
.listGroupRow {
  display: flex;
  flex-direction: row;
}

.listGroupItem {
  border-color: transparent;
  background-color: transparent;
}

/*
 * Provider profile page
 */

 .belowVideoContainer {
  display: flex;
  flex-direction: column;
  margin: 25px;
}

.providerProfileAndLikeContainer {
  display: flex;
  flex-direction: row;
}

.providerProfileContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 10px;
}

.providerProfileCardContainer {
  display: flex;
  flex-direction: row;
  margin:  20px 0px;
}

.providerProfileCardAvatarContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.providerProfileCardAvatar {
  border-radius: 50%;
  height: 8vmax;
  width: 8vmax;
  padding: 1vmin;
}

.providerProfileCardBodyContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 120px;
}

.providerProfileCardName {
  font-size: 1em;
  line-height: 1;
}

.providerProfileCardBioShortText {
  font-size: 0.8em;
  margin-bottom: 40px;
}

.likeSectionContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;
}

.likeIconsContainer {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.likeIcon {
  height: 30px;
  width: 30px;
  margin-bottom: 10px;
  margin-right: 10px;
  filter: invert(69%) sepia(16%) saturate(21%) hue-rotate(314deg) brightness(85%) contrast(88%);
}

.iconNotSelected {
  opacity: 0;
}

.dislikeIcon {
  height: 30px;
  width: 30px;
  margin-top: 10px;
  margin-left: 10px;
  filter: invert(69%) sepia(16%) saturate(21%) hue-rotate(314deg) brightness(85%) contrast(88%);
}

.iconSelected {
  filter: invert(11%) sepia(100%) saturate(6591%) hue-rotate(246deg) brightness(86%) contrast(148%);
}

/*
 * Spacing
 */
.spacer {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  margin-right: 20px;
}

.vertical {
  display: flex;
  flex-direction: column;
  height: 10vh;
}